import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import mitt from 'mitt'
// 导入字体图标
import './assets/fonts/iconfont.css'
import './assets/ylfonts/iconfont.css'
import '@/assets/css/global.css'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'swiper/css/swiper.css'
import VueCropper from 'vue-cropper'
import 'vue-cropper/dist/index.css'
import md5 from 'js-md5'
import VueClipboard from 'vue-clipboard2'

// 引入vuex
import Vuex from 'vuex'
import store from '@/base/store/index'

import { ElLoading } from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// 导入axios
import axios from 'axios'
import base from '@/request/base'
import wechat from '@/base/wechat'
import VXETable from 'vxe-table'
import VXEUI from 'vxe-pc-ui'
import VXETablePluginExportXLSX from 'vxe-table-plugin-export-xlsx'
import ExcelJS from 'exceljs'
import 'vxe-table/lib/style.css'
import 'vxe-pc-ui/lib/style.css'

VXETable.use(VXETablePluginExportXLSX, { ExcelJS })

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
axios.defaults.baseURL = `${base.admin_url}/cloud/`
app.config.globalProperties.$http = axios
app.config.globalProperties.$bus = mitt()
app.config.globalProperties.$md5 = md5
app.config.globalProperties.$filters = {
  thousands(num) {
    if (!num) return '0'
    var str = num.toString()
    var reg = str.indexOf('.') > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g
    return str.replace(reg, '$1,')
  },
}
//创建事件总线
// app.config.globalProperties.$bus = createApp({})

// axios请求拦截器
let loadingInstance = null
axios.interceptors.request.use((config) => {
  var userToken = window.localStorage.getItem('user')
  if (self !== top) {
    config.headers.authorization = localStorage.getItem('auth_token')
  } else {
    if (userToken) {
      if (userToken !== 'undefined') {
        userToken = JSON.parse(userToken).token
        config.headers.Authorization = userToken
      }
    }
  }

  var urlList = [
    'material/download_img',
    'material/other_material',
    'material/show_img',
    'material/grab_image',
    'cloudfb/refresh_balance',
    'Cloudgg/open_account',
    'cloudfb/dashboard',
    'Cloudtt/open_account',
    'Cloudtt/select_name',
    'Cloudtt/account_check',
    'cloudfb/yknow',
    'cloudfb/bill_export',
    'cloudfb/recharge_export',
    'cloudfb/opera_export',
    'cloudfb/violation_export',
    'cloudfb/ad_export',
    'login/wxLogin',
    'login/edit_whchat',
    'user/edit_whchat',
    'cloudfb/set_recharge',
    'cloudfb/search',
    'cloudfb/keyword',
    'cloudfb/chat_record',
    'cloudfb/ad_data',
    'cloudfb/blocked_list',
    'cloudfb/idle_list',
    'cloudfb/ad_list',
    'cloudfb/ad_money',
    'cloudfb/document',
    'cloudfb/recharge_account',
    'cloudfb/resets',
    'cloudfb/set_bm',
    'user/novice_task',
    'bot/page_views',
    'user/set_message',
    'user/message',
    'user/question_list',
    'user/integral_notice',
    'user/system_message',
    'bot/document_feedback',
    'user/menu',
    'user/get_user',
    'user/get_company',
    'user/set_question',
    'bot/page_views',
    'bot/article',
    'bot/feedback',
    'bot/related_questions',
    'customerservice/log',
    'customerservice/score',
    'customerservice/set_score',
    'customerservice/add_chatting_records',
    'customerservice/set_user',
    'material/set_material',
    'material/material_config',
    'file/uploadfile_material',
    'material/set_material_txt',
    'customerservice/get_login',
    'cloudtt/recharge_account',
    'cloudtt/resets',
    'cloudtt/set_bm',
    'cloudtt/ad_data',
    'cloudtt/ad_list',
    'cloudgg/ad_data',
    'cloudgg/ad_list',
    'cloudgg/ad_money',
  ]
  if (!urlList.includes(config.url)) {
    // 展示 Loading 效果
    loadingInstance = ElLoading.service({
      fullscreen: true,
    })
  }
  return config
})

// 声明响应拦截器
axios.interceptors.response.use((response) => {
  // 隐藏 Loading 效果
  if (loadingInstance) {
    loadingInstance.close()
  }
  return response
})
// 全局注册搜索组件
// app.component('search-form',SearchForm)

app.use(ElementPlus, {
  locale: zhCn,
})
app.use(VXEUI)
app.use(VXETable)
app.use(store)

app.use(VueCropper)
app.use(VueClipboard)
app.use(Vuex)
app.use(router)
app.mount('#app')

let killEnterEven = (event) => {
  if (event.key === 'Enter') {
    if (event.preventDefault) {
      event.preventDefault()
    } else {
      event.returnValue = false
    }
  }
}

app.directive('rolePermissionBtn', (el, binding) => {
  if (binding.value) {
    if (store.getters.rolePermission.includes(binding.value) || store.getters.userInfo.manage === 1) {
      el.style.display = 'inline'
    } else {
      el.style.display = 'none'
    }
  }
})

// cosmic子账户隐藏通知管理、用户信息、Yknow机器人、帮助中心、问题反馈、商务经理
app.directive('cosmicChildAccount', (el, binding) => {
  if (
    store.getters.userInfo.manage !== 1 &&
    (store.getters.userInfo.crm_id === 91122 || store.getters.userInfo.crm_id === 27563)
  ) {
    el.style.display = 'none'
  } else {
    el.style.display = binding.value || 'inline'
  }
})

// 判断是否为移动端
if (
  /Mobile|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) &&
  window.location.pathname !== '/start' &&
  window.location.pathname !== '/startMobile' &&
  window.location.pathname !== '/register' &&
  window.location.pathname !== '/mobileRegisterSuccess'
) {
  router.push('/mobile')
}

document.onkeyup = killEnterEven
document.onkeydown = killEnterEven
document.onkeypress = killEnterEven
