/**
 * axios封装
 * 请求拦截、响应拦截、错误统一处理
 */
import axios from 'axios'
import router from '@/router'
import store from '@/base/store'
import { ElMessage } from 'element-plus'

/**
 * 跳转登录页
 * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
 */
const toLogin = async () => {
  localStorage.removeItem('userInfo')
  localStorage.removeItem('accountBannerClosed')
  await store.dispatch('EDIT_USERINFO', undefined)

  if (router.currentRoute.value.path === '/homepage') {
    router.push('/login')
    ElMessage.warning('服务器用户登录信息认证失败，请重新登录')
    window.location.reload()
    return
  }
  ElMessage.warning({
    message: '服务器用户登录信息认证失败，请重新登录',
    duration: 1000,
    onClose() {
      if (['/login', '/register'].indexOf(router.currentRoute.value.path) >= 0) return router.push('/login')
      router.push({
        path: '/login',
        query: { goto: router.currentRoute.value.path },
      })
      window.location.reload()
    },
  })
}

/**
 * 请求失败后的错误统一处理
 * @param {Number} status 请求失败的状态码
 */
const errorHandle = (status, msg) => {
  console.log('http', msg)
  // 状态码判断
  switch (status) {
    case 300:
      toLogin()
      break
    case 404:
      ElMessage.error('请求的资源不存在' + msg)
      break
    case 100:
      if (msg) ElMessage.error(msg)
      break
    case 101:
      if (msg) ElMessage.error(msg)
      break
    default:
      ElMessage.error('服务器异常，请刷新页面重试')
  }
}

// 创建axios实例
let instance = axios.create({ timeout: 1000 * 60 })
// 设置post请求头
instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

// 全局请求列表初始化
if (!window.pendingReqMap) {
  window.pendingReqMap = new Map()
}

//请求取消类型
const REQUEST_TYPE = {
  DUPLICATED_REQUEST: 'duplicatedRequest',
}

// 请求唯一标识
const duplicatedKeyFn = (config) => {
  return `${config.method}${config.url}${JSON.stringify(config.params)}`
}

//新增请求
const addPendingReqList = (config) => {
  const duplicatedKey = JSON.stringify({
    duplicatedKey: duplicatedKeyFn(config),
    type: REQUEST_TYPE.DUPLICATED_REQUEST,
  })

  config.cancelToken =
    config.cancelToken ||
    new axios.CancelToken((cancel) => {
      if (duplicatedKey && !window.pendingReqMap.has(duplicatedKey)) {
        window.pendingReqMap.set(duplicatedKey, cancel)
      }
    })
}

//删除历史请求
const removePendingReqList = (config, needDelete = true) => {
  const duplicatedKey = JSON.stringify({
    duplicatedKey: duplicatedKeyFn(config),
    type: REQUEST_TYPE.DUPLICATED_REQUEST,
  })

  //存在历史相同请求记录
  if (duplicatedKey && window.pendingReqMap.has(duplicatedKey)) {
    if (needDelete) {
      const cancel = window.pendingReqMap.get(duplicatedKey)
      cancel(duplicatedKey)
      window.pendingReqMap.delete(duplicatedKey)
    } else {
      return true
    }
  }

  return false
}

/**
 * 请求拦截器
 * 每次请求前，如果存在token则在请求头中携带token
 */
instance.interceptors.request.use(
  (config) => {
    if (config.params && (config.params.account_id || config.params.account_ids)) {
      if (config.params.account_id && typeof config.params.account_id === 'object') {
        config.params.account_id = config.params.account_id['account_id']
      }
    }

    if (config.method === 'get' && !config.url.includes('/user/get_yc_rule')) {
      //非提交类的请求，直接结束历史相同请求
      removePendingReqList(config, true)
    } else {
      //提交类请求，请求防抖
      let cancelCurrentReq = removePendingReqList(config, false)

      if (cancelCurrentReq) {
        return Promise.reject('处理中，请勿重复请求')
      } else {
        removePendingReqList(config, true)
      }
    }

    //请求列表添加
    addPendingReqList(config)
    const token = !store.getters.tokenInfo ? null : store.getters.tokenInfo
    if (self !== top) {
      config.headers.authorization = localStorage.getItem('auth_token')
    } else {
      if (token) {
        config.headers.authorization = token
      }
    }

    store.dispatch('EDIT_LOADING', true)
    return config
  },
  (error) => Promise.error(error)
)

instance.interceptors.response.use(
  async (res) => {
    //删除历史请求
    removePendingReqList(res.config, true)
    store.dispatch('EDIT_LOADING', false)
    if (
      [204].indexOf(res.status) >= 0 ||
      res.data.code === 200 ||
      (res.config.url.includes('/cloud/login/login') && res.data.code === '1') ||
      (res.config.url.includes('/cloud/login/set_company') && res.data.code === 100) ||
      (res.config.url.includes('/cloud/advoperate/judge_authorize') && res.data.code === 100) ||
      (res.headers && res.headers['content-type'] && res.headers['content-type'].indexOf('text/html;') >= 0) ||
      (res.config.url.includes('/cloud/cloudfb/dl') && res.data.code < 200)
    ) {
      return Promise.resolve(res.data)
    }

    if (!res.config.url.includes('/cloud/advoperate/create_adv')) {
      errorHandle(res.data.code, res.data.msg)
    }

    return Promise.reject(res.data)
  },
  async (error) => {
    // 如果是取消请求类型则忽略异常处理
    let isDuplicatedType
    try {
      const errorType = (JSON.parse(error.message) || {}).type
      isDuplicatedType = errorType === REQUEST_TYPE.DUPLICATED_REQUEST
    } catch (error) {
      isDuplicatedType = false
    }

    //非重复请求异常处理
    if (!isDuplicatedType) {
      store.dispatch('EDIT_LOADING', false)
      const { response } = error
      if (response) {
        errorHandle(response.status, response.data.message)
        return Promise.reject(response)
      } else {
        if (!window.navigator.onLine) {
          store.commit('changeNetwork', false)
        } else {
          return Promise.reject(error)
        }
      }
    }
  }
)

export default instance
