import axios from '@/request/http'
import base from '@/request/base'

/**
 * 存储用户链接来源
 * @param {object} params
 */
export const saveUserSource = (params) => {
  return axios.get(`${base.admin_url}/cloud/user/user_source`, { params })
}

export const getQwQRCode = () => {
  return axios.get(`${base.admin_url}/cloud/cloudfb/get_qw`)
}

/** 生成跳转小程序scheme */
export const getWechatScheme = () => {
  return axios.get(`https://acadmin.yinolink.com/newmobile/index/xcx_scheme?type=4`)
}

/** 记录2024问卷调查触达人数 */
export const recordQuestionUser = () => {
  return axios.get(`${base.admin_url}/cloud/user/question_user`)
}

/** 获取短链 */
export const generateShortLink = (link, adset) => {
  return axios.post(`${base.admin_url}/cloud/cloudfb/dl`, { dl: link, adset: adset })
}

/** 新手指引 */
export const setIntro = (type) => {
  return axios.post(`${base.admin_url}/cloud/user/set_intro`, { type })
}

/** 获取手机验证码 */
export const getPhoneCode = (phone) => {
  return axios.post(`${base.admin_url}/cloud/login/login`, { type: 'code', phone })
}

/** 卓达验证码验证接口 */
export const checkPhoneCode = (code) => {
  return axios.get(`${base.admin_url}/cloud/cloudfb/send_zhuo_phone?phoneCode=${code}`)
}

/** 记录iframe的使用人数 */
export const setIframeLog = (duration) => {
  return axios.post(`${base.admin_url}/cloud/material/set_iframe_log?duration=${duration}`)
}

/** 防盗刷提醒 */
export const setStealingRemind = (data) => {
  return axios.post(`${base.admin_url}/cloud/user/stealing_remind`, data)
}
