export default {
  namespace: 'true',
  state() {
    return {
      user: JSON.parse(localStorage.getItem('user') || '{}'),
      token: localStorage.getItem('token'),
      loading: false,
      rolePermission: JSON.parse(localStorage.getItem('rolePermission') || '[]'),
      role: null,
    }
  },
  mutations: {
    async showLoading(state, loading) {
      state.loading = loading
    },
    async changeUserToken(state, user) {
      state.user = user
      if (undefined === user || undefined === user.token) {
        state.token = undefined
        localStorage.removeItem('token')
      } else {
        state.token = user.token
        localStorage.setItem('token', user.token)
      }
    },
    async changeRolePermission(state, rolePermission) {
      state.rolePermission = rolePermission
      localStorage.setItem('rolePermission', JSON.stringify(rolePermission))
    },
    async changeRole(state, role) {
      state.role = role
    },
  },
  actions: {
    EDIT_USERINFO({ commit }, user) {
      return new Promise((res) => {
        commit('changeUserToken', user)
        if (undefined === user) {
          localStorage.removeItem('user')
        } else {
          localStorage.setItem('user', JSON.stringify(user))
        }
        res()
      })
    },
    EDIT_LOADING({ commit }, loading) {
      return new Promise((res) => {
        commit('showLoading', loading)
        res()
      })
    },
  },
  getters: {
    isLoading: (state) => {
      return state.loading
    },
    isLogged: (state) => {
      if (state.user && state.user.name && state.token) return true
      return false
    },
    tokenInfo: (state) => {
      return state.token
    },
    userInfo: (state) => {
      return state.user
    },
    rolePermission: (state) => {
      return state.rolePermission
    },
    role: (state) => {
      return state.role
    },
  },
}
